import React from "react";
import "./about.css";
import ahmed5 from "../../assets/ahmed5.jpg";
import cr1 from "../../assets/cr1.png";
import cr2 from "../../assets/CZECH_Degree.png"
import cr3 from "../../assets/Palestine.png"

import "./card.css";
import { FaAward } from "react-icons/fa";

const about = () => {

  return (
    <section id="about">
      <h1>About ME!</h1>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ahmed5} alt="personal" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-card-front">
                    <article className="about__card">
                      <FaAward className="about__icon" />
                      <h5>Master's Degree</h5>
                      <small>System Engineering and Informatics</small>
                    </article>
                  </div>
                </div>
                <div className="flip-card-back">
                  <img id="cr" src={cr2} alt="certification"></img>
                </div>
              </div>
            </div>
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <article className="about__card">
                    <FaAward className="about__icon" />
                    <h5>Bachelor's Degree</h5>
                    <small>Computer Engineering</small>
                  </article>
                </div>
                <div class="flip-card-back">
                  <img id="cr" src={cr3} alt="certification"></img>
                </div>
              </div>
            </div>
            <div class="flip-card">
              <div class="flip-card-inner">
                <div class="flip-card-front">
                  <div class="flip-card-front">
                    <article className="about__card">
                      <FaAward className="about__icon" />
                      <h5>w3schools</h5>
                      <small>JavaScript certification</small>
                    </article>
                  </div>
                </div>
                <div class="flip-card-back">
                  <img id="cr" src={cr1} alt="certification"></img>
                </div>
              </div>
            </div>
          </div>

          <p>
            I am a FreeLancer Developer, a recent graduate of the Czech University of
            Life Sciences Prague with a master's degree in system engineering
            and informatics. My Bachelor's degree is in Computer Systems
            Engineering from PPU. With expertise in HTML5, Tailwind CSS,
            JavaScript,TypeScript,Redux,Nodejs, Relational Databases, and React.
            I speak Arabic as my native language and English at an
            upperintermediate level. One and a half years experience as a
            website developer and two years experience as a manufacturing
            engineer in a glass factory. I have successfully completed my
            studies and I am eagerly poised to embark on a journey to acquire
            new real-world experience with utmost passion and enthusiasm.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk{" "}
          </a>
        </div>
      </div>
    </section>
  );
};

export default about;
