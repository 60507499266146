import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {BsFacebook} from 'react-icons/bs'
import {AiFillGithub} from 'react-icons/ai'


const HeaderSocial = () => {
  return (
    <div className="header__socials">
        <a href="https://www.linkedin.com/in/ahmed-tebakhi" target="blank"><BsLinkedin/></a>
        <a href="https://github.com/TebakhiAhmed" target="blank"><AiFillGithub /></a>
        <a href="https://www.facebook.com/ahmad.tbakhi/" target="blank"> <BsFacebook/></a>


    </div>
  )
}

export default HeaderSocial