import React from 'react'
import './nav.css'

import {AiOutlineHome} from 'react-icons/ai'
import {AiOutlineUser} from 'react-icons/ai'
import {BiBook} from 'react-icons/bi'
import {AiOutlineContacts} from 'react-icons/ai'
import {AiOutlineSafetyCertificate} from 'react-icons/ai'
// import {FcServices} from 'react-icons/fc'
import { useState } from 'react'






const Nav = () => {
  const [activeNav,setActiveNav] = useState('#home')
  return (
    <nav>
      <a href="#home" onClick={() => setActiveNav('#home')} className={activeNav === '#home' ? 'active' :''}><AiOutlineHome /> </a>
      <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' :''}><AiOutlineUser /> </a>
      <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' :''}><BiBook /> </a>
      {/* <a href="#services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' :''}><FcServices /> </a> */}
      <a href="#atch" onClick={() => setActiveNav('#atch')} className={activeNav === '#atch' ? 'active' :''}><AiOutlineSafetyCertificate /> </a>
      <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' :''}><AiOutlineContacts /> </a>
    </nav>
  )
}

export default Nav