import React from 'react'
import './header.css'
import './text.scss'
import CTA from './CTA'
import ME from '../../assets/ahmad.jpg'
import ahmed2 from '../../assets/ahmed2.png'
import ahmed3 from '../../assets/ahmed3.jpg'
import ahmed4 from '../../assets/ahmed4.jpg'

import { useState, useEffect } from 'react';
import HeaderSocial from './HeaderSocial'


const Header = () => {

  const photos = [
    ME, ahmed2,ahmed3,ahmed4
  ];


  
  //Funtion to display more than one picture
  const Slideshow = () => {
    const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
      }, 4000);
  
      return () => clearInterval(interval);
    }, []);
  
    return (
      photos[currentPhotoIndex]
    );
  };


  
  return (
    <section id="home">
      
      <div className="container header__container">

        <div className='header__slider'>
          <div className='header__div'>
            <div>
            <span className= "text__header">I'm Ahmed Tebakhi</span>
            <br/>
            <span id="Ahmed_name">A web Developer</span>
            </div>
            <div className='me'>
                <img  src={Slideshow()} alt="personal" id='slideShow'></img>
            </div>
          </div>
        </div>

        <CTA />
        <HeaderSocial />


        <a href="#contact" className="scroll__down"> Scroll Down </a>

      </div>
    </section>
  )
}

export default Header