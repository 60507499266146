import React from "react";
import "./experience.css";
import { BsPatchCheck } from "react-icons/bs";

const Experience = () => {
  return (
    <section id="experience">
      <h1>My Experience</h1>
      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Develompent</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h5>HTML & CSS</h5>
                <small className="text-light">
                  Using TailwindCSS, CSS3, SASS
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h5>Javascript & TypeScript</h5>
                <small className="text-light">
                  Using Reactjs,React Hooks, Redux Thunk / Redux Saga, AJAX
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h5>UI/UX design</h5>
                <small className="text-light">
                  using Bootstrap to responsive grid system, pre-designed
                  components, and customizable themes{" "}
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h5>Testing and Debugging</h5>
                <small className="text-light">
                  React Testing Library, simulate user events, xperform
                  assertions, Postman
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h5>TailwindCSS utility-first CSS framework</h5>
                <small className="text-light">
                  pre-built components, low-level utility classes, conjunction
                  with React library
                </small>
              </div>
            </article>
          </div>
        </div>
        <div className="experience__backend">
          <h3>BackEnd Develompent</h3>
          <div className="experience__content">
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>JavaScript/Node.js</h4>
                <small className="text-light">
                  Enables full-stack JavaScript development
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Express.js/Axios</h4>
                <small className="text-light">
                  A lightweight and flexible framework for building web
                  applications with Node.js.
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Database Management</h4>
                <small className="text-light">
                  Knowledge of database systems and experience in working with
                  databases include MySQL, PostgreSQL
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>API Development</h4>
                <small className="text-light">
                  Understanding how to design, build, and consume APIs
                  (Application Programming Interfaces) for data exchange between
                  different systems.
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Version Control</h4>
                <small className="text-light">
                version control systems like Git to manage code repositories, track changes, and collaborate with other developers.
                </small>
              </div>
            </article>
            <article className="experience__details">
              <BsPatchCheck className="experience__details-icon" />
              <div>
                <h4>Testing and Debugging</h4>
                <small className="text-light">
                Knowledge of testing frameworks and methodologies to write unit tests, perform integration testing, and debug backend code
                </small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
