import React, {useRef} from "react";
import emailjs from '@emailjs/browser';

import { MdOutlineEmail } from "react-icons/md";
import { BsMessenger } from "react-icons/bs";
import { SiWhatsapp } from "react-icons/si";
import "./contact.css";

const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {

    document.getElementById("button1").value = "loading ..."
    e.preventDefault();
    emailjs.sendForm('service_3937mze', 'template_s5482c5', form.current, 'rb5IBBtCgs3SlZZcr')
      .then((result) => {
          alert("Thank you, Your message was sent successfully...")
          console.log(result)
          document.getElementById("button1").value = "send"
          console.log("message sent....")
      }, (error) => {
              alert("SOMETHING WRONG !!!! ... Please be sure that you fill everything correctly.");
              document.getElementById("button1").value = "send"
              console.log(error)
            });
  };


  return (
    <section id="contact">
      <h1>Contact Me</h1>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icons" />
            <h4>Email</h4>
            <h5>info@ahmedtebakhi.com</h5>
            <a href="mailto:info@ahmedtebakhi.com">Send me a message</a>
          </article>
          <article className="contact__option">
            <BsMessenger className="contact__option-icons" />
            <h4>Messenger</h4>
            <h5>Ahmed Tebakhi</h5>
            <a href="https://m.me/ahmad.tbakhi">Send me a message</a>
          </article>
          <article className="contact__option">
            <SiWhatsapp className="contact__option-icons" />
            <h4>What's App</h4>
            <h5>+420773682312</h5>
            <a href="https://api.whatsapp.com/send?phone+=420773682312">
              Send me a message
            </a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input type="text" name="user_name" />
          <label>Email</label>
          <input type="email" name="user_email" />
          <label>Message</label>
          <textarea name="message" />
          <input id='button1' className='btn' type="submit" value="Send" />
        </form>
      </div>
    </section>
  );
};

export default Contact;
