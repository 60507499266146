import React from 'react'
import './atch.css'
import IMG1 from '../../assets/nclinic.jpg'
import IMG2 from '../../assets/imgp3.png'
import IMG3 from '../../assets/chikitsa.png'



const data = [
    {

        id: 1,
        image: IMG1,
        title: 'N Clinic Website',
        name: 'Preview',
        demo: 'https://www.nclinic.cz'
    },
    {
        id: 2,
        image: IMG2,
        title: 'Central Parking Prague',
        name: 'Preview',
        demo: 'https://www.centralparkingprague.com/'
    },
    {
        id: 3,
        image: IMG3,
        title: 'Dental Management System',
        name: 'Coming Soon',
        demo: 'http://hledamzubare.cz/'
    },
]

const Atch = () => {
  return (
    <section id="atch">
        <h1>The Lastest Projects</h1>

        <div className="container portfolio__container">
            {
                data.map(({id,image,title,demo, name}) => {
                    return (
                        
                        <article id= {id} className="portfolio__item">
                        <div className="portfolio__item-image">
                            <img src={image} alt="Project number 1" />
                        </div>
                            <h3>{title}</h3>
                            <div className="portfolio__item-cta">
                                <a href={demo} className="btn btn-primary " target="blank" >{name}</a>
                            </div>
                    </article>
                    )

                })

            }
        </div>
    </section>

  )
}

export default Atch