import React from 'react'
import About from './components/about/About'
import Contact from './components/contact/Contact'
import Experience from './components/experience/Experience'
import Nav from './components/nav/Nav'
import Header from './components/header/Header'
//import Services from './components/services/Services'
import Atch from './components/atchivment/Atch'
const App = () => {
  return (
    <>
        <Header />

        <Nav />
        <About />
        <Experience />
        <Atch />
        <Contact />
    </>
  )
}

export default App